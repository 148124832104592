import React, { useMemo } from 'react';
import MetaTags from 'react-meta-tags';
import slugify from 'slugify';
import toastr from 'toastr';
import AcitvityDetails from '../activity-details';
import ActivityPracticalInfo from '../activity-practical-info';
import BookButton from '../../components/BookButton/BookButton';

import styles from '../activity-page/index.module.scss';
import Layout from '../../components/Layout/Layout';
import BreadcrumbsActivity from '../../components/BreadcrumbsActivity/BreadcrumbsActivity';
import {
  ACTIVITY_BOOK_BUTTON,
  COPIED_SUCCESS,
} from '../../constans/texts/texts';
import {
  SeoData,
  StructuredDataActivity,
} from '../../components/StructuredData/StructuredData';
import ShareIcon from '../../assets/img/icons/share.svg';
import BookmarkIcon from '../../assets/img/icons/ic_bookmark_default.svg';
import { PHONE_WIDTH_BREAKPOINT } from '../../constans/header';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import ReviewDetailsSEO from '../../components/ReviewDetailsSEO/ReviewDetailsSEO';

function removeDots(text) {
  return text.replace(/\./g, '');
}

function ActivityPageStatic({
  tabsClassName,
  switchersClassName,
  isSearchResult,
  goBackButton,
  buttonsContainerClasses = '',
  bookActivityButtonWrapperClasses = '',
  pageContext,
}) {
  const windowWidth = useWindowWidth();
  const isMobile = useMemo(() => windowWidth <= PHONE_WIDTH_BREAKPOINT, [
    windowWidth,
  ]);
  const { details } = pageContext;
  const { id, country, region, city, urlDestination, urlName } = details;

  const cityPart = slugify(
    `${urlDestination || city?.name || region?.name || country?.name}`
  );
  const clearCityPart = removeDots(cityPart);
  const activityPart = slugify(`${urlName || details?.title}`);

  const handleCopy = () =>
    navigator.clipboard
      .writeText(
        cityPart && activityPart
          ? `${window?.location?.origin}/activities/${cityPart}/${activityPart}`
          : `${window?.location?.origin}/activities?id=${id}`
      )
      .then(() => toastr.success(COPIED_SUCCESS, '', { timeOut: 500 }));

  return (
    <Layout>
      <MetaTags>
        {details?.metaDescription && (
          <meta
            id="description"
            name="description"
            content={details?.metaDescription}
          />
        )}
        {details?.metaKeywords && (
          <meta
            id="meta-keywords"
            name="keywords"
            content={details?.metaKeywords}
          />
        )}
      </MetaTags>
      <SeoData urlPath={`/activities/${clearCityPart}/${activityPart}`} />
      <StructuredDataActivity article details={details} />
      <div className="container">
        <BreadcrumbsActivity
          country={details.country}
          region={details.region}
          city={details.city}
          district={details.district}
          name={details.title}
        />
        <div
          className={`${
            buttonsContainerClasses || styles.headerButtonsContainer
          }`}
        >
          {goBackButton}
          <BookButton
            details={details}
            buttonWrapperClasses={
              bookActivityButtonWrapperClasses ||
              styles.bookActivityButtonWrapper
            }
            text={ACTIVITY_BOOK_BUTTON}
          />
        </div>

        <div
          className={`${styles.activityButtons} flex flex-v-center flex-shrink-0`}
        >
          <button onClick={handleCopy} type="button" className="iconButton">
            <ShareIcon />
          </button>
          <button
            disabled
            type="button"
            className={`${styles.bookmark} iconButton`}
          >
            <BookmarkIcon />
          </button>
        </div>

        <AcitvityDetails id={details.id} details={details} />

        <div className={styles.marginTop16} />
        <ActivityPracticalInfo
          isSearchResult={isSearchResult}
          details={details}
        />

        <ReviewDetailsSEO propertyData={details} />
      </div>
    </Layout>
  );
}

export default ActivityPageStatic;
