import React, { useEffect, useState } from 'react';

import styles from './ReviewDetailsSEO.scss';
import destinationsService from '../../services/destinationsService';
import {
  generateCityLocationLinkPath,
  generateRegionLocationLinkPath,
  getCityIds,
  getRegionIds,
} from '../../helpers/generateLinks';

function ReviewDetailsSEO({ propertyData }) {
  const [propertiesCountInTheCity, setPropertiesCountInTheCity] = useState(0);
  const [propertiesCountInTheRegion, setPropertiesCountInTheRegion] = useState(
    0
  );

  const [activitiesCountInTheCity, setActivitiesCountInTheCity] = useState(0);
  const [activitiesCountInTheRegion, setActivitiesCountInTheRegion] = useState(
    0
  );

  const region = propertyData.region?.name || undefined;
  const city = propertyData.city?.name || undefined;

  const cityIds = getCityIds(propertyData);
  const regionIds = getRegionIds(propertyData);

  useEffect(() => {
    const getListOfProperties = async () => {
      const {
        pagination,
      } = await destinationsService().getDestinationProperties({
        ...cityIds,
        offset: 0,
        limit: 10,
      });

      setPropertiesCountInTheCity(pagination.totalCount || 0);
    };

    const getListOfActivities = async () => {
      const {
        pagination,
      } = await destinationsService().getDestinationActivities({
        ...cityIds,
        offset: 0,
        limit: 10,
      });

      setActivitiesCountInTheCity(pagination.totalCount || 0);
    };

    const getListOfRegionProperties = async () => {
      const {
        pagination,
      } = await destinationsService().getDestinationProperties({
        ...regionIds,
        offset: 0,
        limit: 10,
      });

      setPropertiesCountInTheRegion(pagination.totalCount || 0);
    };

    const getListOfRegionActivities = async () => {
      const {
        pagination,
      } = await destinationsService().getDestinationActivities({
        ...regionIds,
        offset: 0,
        limit: 10,
      });

      setActivitiesCountInTheRegion(pagination.totalCount || 0);
    };

    getListOfActivities();
    getListOfProperties();
    getListOfRegionActivities();
    getListOfRegionProperties();
  }, [propertyData]);

  return (
    <div>
      {(activitiesCountInTheRegion > 20 || propertiesCountInTheRegion > 20) && (
        <div className={styles.block}>
          >> Plan an itinerary in{' '}
          {activitiesCountInTheCity > 20 && (
            <a href={generateCityLocationLinkPath(propertyData, 'plan')}>
              {city}
            </a>
          )}{' '}
          or{' '}
          <a href={generateRegionLocationLinkPath(propertyData, 'plan')}>
            {region}
          </a>
        </div>
      )}
    </div>
  );
}

export default ReviewDetailsSEO;
